import { ethers} from "ethers";
import { farmAbi, tokenAbi } from "./abis";

let signer : ethers.JsonRpcSigner;
let provider : ethers.BrowserProvider;

export interface Metrics {
    roi: number;
    balance: number;
    stakedBalance: number;
    rewards: number;
  }

const slp_address = "0xdaC0324DEbd1F036323C0e207A4cd8e78ee6BDFF";
const farm_address = "0x30394955e0AD22Dc96eC7Eb01d70C8594c899Aa7";


export const connect = async (set: (a: boolean) => void) => {
    const ethereum = (window as any).ethereum;
    if (typeof ethereum != "undefined") {
        provider = new ethers.BrowserProvider(ethereum);
        signer = await provider.getSigner();
        set(true);
    } else {
        alert("Please install metamask!");
    }
};


export const get_info = async(set: (a: Metrics) => void) => {
    const ethereum = (window as any).ethereum;
    const farmContract = new ethers.Contract(farm_address, farmAbi, provider);
    const slpContract = new ethers.Contract(slp_address, tokenAbi, provider);
    const farmSigner  = new ethers.Contract(farm_address, farmAbi, signer);

    let balance =  parseFloat(ethers.formatEther(await slpContract.balanceOf(ethereum.selectedAddress)));
    let reward = parseFloat(ethers.formatEther(await farmSigner.getPending()));
    let perShare = parseFloat(ethers.formatEther(await farmContract.xftPerShare()));
    const totalStakes = parseFloat(ethers.formatEther(await slpContract.balanceOf(farm_address)));
    const stakedTokens = parseFloat(ethers.formatEther((await farmContract.Stakers(ethereum.selectedAddress))[0]));
    const blockPerYear = (365 * 24 * 60 * 60) / 12;
    const rate = perShare / totalStakes;
    let apy = Math.pow((1 + rate*100/blockPerYear), blockPerYear) - 1; 
    set({roi: Math.round(apy), balance: balance, stakedBalance: stakedTokens, rewards: reward});
}

export const stake = async (amount: number) => {
    const ethereum = (window as any).ethereum;
    const farmSigner  = new ethers.Contract(farm_address, farmAbi, signer);
    const slpContract = new ethers.Contract(slp_address, tokenAbi, provider);
    const slpSigner  = new ethers.Contract(slp_address, tokenAbi, signer);
    const weiAmount = ethers.parseEther(amount.toString());
    
    const allowance = await slpContract.allowance(ethereum.selectedAddress, farm_address);
    if (allowance < weiAmount){
        await slpSigner.approve(farm_address, weiAmount);
    }
    await farmSigner.deposit(weiAmount);
}


export const withdraw = async (amount: number) => {
    const ethereum = (window as any).ethereum;
    const farmContract = new ethers.Contract(farm_address, farmAbi, provider);
    const farmSigner : any = farmContract.connect(signer);
    const weiAmount = ethers.parseEther(amount.toString());

    await farmSigner.withdraw(weiAmount);
}

export const harvest = async () => {
    const ethereum = (window as any).ethereum;
    const farmContract = new ethers.Contract(farm_address, farmAbi, provider);
    const farmSigner : any = farmContract.connect(signer);

    await farmSigner.harvest();
}