import React, { useState, useEffect } from "react";
import { connect, stake, withdraw, harvest, get_info, Metrics } from "./utils";

function App() {
    const [connected, setConnection] = useState(false);
    const [tab, setTab] = useState("stake");
    const [selectedAmount, changeAmount] = useState(0);

    const [data, setData] = useState({} as Metrics);

    useEffect(() => {
        if (connected) {
            get_info(setData);
        }
    }, [connected]);

    const selectTab = () => {
        if (tab === "stake") {
            return (
                <div className="card w-auto p-4 bg-cardPurple shadow-xl flex flex-col">
                    <div className="btn-group mb-2">
                        <button className="btn btn-active btn-outline bg-input">
                            STAKE
                        </button>
                        <button
                            className="btn btn-outline text-txt border-0"
                            onClick={() => {
                                setTab("unstake");
                            }}
                        >
                            unstake
                        </button>
                        <button
                            className="btn btn-outline text-txt border-0"
                            onClick={() => {
                                setTab("harvest");
                            }}
                        >
                            rewards
                        </button>
                    </div>
                    <p className="my-3">Amount</p>
                    <input
                        type="number"
                        onChange={(e) => changeAmount((e.target as any).value)}
                        className="input input-bordered border-input w-full max-w-xs bg-cardPurple mb-4"
                        name=""
                        id=""
                    />
                    <p>Balance: {data.balance} SLP</p>
                    <p>XFT APY: {data.roi}%</p>
                    <button
                        className="btn mt-4 btn-primary bg-input"
                        onClick={() => {
                            if (data.rewards === 0) {
                                stake(selectedAmount);
                            }else{
                                alert("please harvest your rewards before depositing");
                            }
                        }}
                    >
                        Approve & Stake
                    </button>
                </div>
            );
        } else if (tab === "unstake") {
            return (
                <div className="card w-auto p-4 bg-cardPurple shadow-xl flex flex-col">
                    <div className="btn-group mb-2">
                        <button
                            className="btn btn-outline text-txt border-0"
                            onClick={() => {
                                setTab("stake");
                            }}
                        >
                            STAKE
                        </button>
                        <button className="btn btn-outline btn-active btn-input">
                            unstake
                        </button>
                        <button
                            className="btn btn-outline text-txt border-0"
                            onClick={() => {
                                setTab("harvest");
                            }}
                        >
                            rewards
                        </button>
                    </div>
                    <p className="my-3">Amount</p>
                    <input
                        type="number"
                        onChange={(e) => changeAmount((e.target as any).value)}
                        className="input input-bordered bg-cardPurple border-input w-full max-w-xs mb-4"
                        name=""
                        id=""
                    />
                    <p>Staked balance {data.stakedBalance} SLP</p>
                    <button
                        className="btn mt-2 btn-primary bg-input"
                        onClick={() => {
                            withdraw(selectedAmount);
                        }}
                    >
                        unStake
                    </button>
                </div>
            );
        } else if (tab === "harvest") {
            return (
                <div className="card w-auto p-4 bg-cardPurple shadow-xl flex flex-col">
                    <div className="btn-group mb-2">
                        <button
                            className="btn btn-outline btn-input text-txt border-0"
                            onClick={() => {
                                setTab("stake");
                            }}
                        >
                            STAKE
                        </button>
                        <button
                            className="btn btn-outline btn-input text-txt border-0"
                            onClick={() => {
                                setTab("unstake");
                            }}
                        >
                            unstake
                        </button>
                        <button className="btn btn-outline btn-active text-txt border-0">
                            rewards
                        </button>
                    </div>
                    <p>Available XFT rewards: {data.rewards}</p>
                    <button
                        className="btn mt-2 btn-primary bg-input"
                        onClick={harvest}
                    >
                        harvest
                    </button>
                </div>
            );
        } else {
            return (
                <div className="card w-auto p-4 bg-cardPurple shadow-xl flex flex-col">
                    <div className="btn-group mb-2">
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                setTab("stake");
                            }}
                        >
                            STAKE
                        </button>
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                setTab("unstake");
                            }}
                        >
                            unstake
                        </button>
                        <button
                            className="btn btn-outline"
                            onClick={() => {
                                setTab("harvest");
                            }}
                        >
                            rewards
                        </button>
                    </div>
                    <h1>Invalid tab!!</h1>
                </div>
            );
        }
    };

    return (
        <div>
            <div>
                <div className="navbar bg-base-100">
                    <div className="navbar-start">
                        <a
                            className="btn btn-ghost normal-case text-xl"
                            href="https://offshift.io"
                        >
                            <img
                                className="w-auto h-10"
                                src="/logo.svg"
                                alt="offshift"
                            />
                        </a>
                    </div>
                    <div className="navbar-end">
                        {connected ? (
                            <a className="btn btn-outline btn-active">
                                Connected
                            </a>
                        ) : (
                            <a
                                className="btn btn-outline"
                                onClick={() => {
                                    connect(setConnection);
                                }}
                            >
                                Connect
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className="min-h-screen bg-backPurple text-txt pt-40">
                <div className="flex flex-col gap-4 justify-center items-center">
                    {connected ? (
                        <div>{selectTab()}</div>
                    ) : (
                        <div className="text-center">
                            <h1 className="text-2xl front-bold py-2">
                                Please connect your wallet to continue
                            </h1>
                            <button
                                className="btn btn-primary w-40"
                                onClick={() => {
                                    connect(setConnection);
                                }}
                            >
                                Connect
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default App;
